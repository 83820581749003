import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/share-buttons"
import CTA from "../components/cta"
import ExplorerTag from "../components/explorer-tag"

import "./blog-post.module.scss"

const BlogPostTemplate = ({ data }) => {
  const post = data && data.contentfulBlogPost
  const url =
    (typeof window !== "undefined" && window.location.href) ||
    `https://mysha.pe/blog/${post.slug}`
  return (
    <Layout>
      <div style={{ background: "#fff" }}>
        <SEO
          title={post.title}
          meta={[
            {
              property: "og:title",
              content: post.title,
            },
            // {
            //   property: 'og:image',
            //   content: post.heroImage.src
            // },
            {
              property: "og:description",
              content: post.subtitle,
            },
            {
              property: "og:url",
              content: url,
            },
          ]}
        />
        <div styleName="hero-image">
          <Img alt={post.title} fluid={post.heroImage.fluid} />
        </div>
        <Container styleName="content-wrapper">
          {post.tags && post.tags.length > 0 && (
            <div className="blog-tag">{post.tags[0]}</div>
          )}
          <h1 styleName="title">
            {post.title}
            <br />
            <small>{post.subtitle}</small>
          </h1>
          <div styleName="info-bar">
            <div styleName="date-type">
              {post.publishDate} <span styleName="dot">·</span>{" "}
              {post.type || "Blog"}
            </div>
            <div styleName="share-buttons">
              <ShareButtons url={url} title={post.title} size={25} />
            </div>
          </div>
          <hr />
          <div
            styleName="content"
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
          <div className="explorer-tag">
            <ExplorerTag tag={post.tags[0]} />
          </div>
        </Container>
        <CTA isFreeReport={true} />
      </div>
    </Layout>
  )
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      tags
      subtitle
      publishDate(formatString: "MMMM D, YYYY")
      heroImage {
        fluid(maxWidth: 1300) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
