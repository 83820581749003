import React from 'react';

const images = {
  'corporate-culture' : require('../images/explorers/corporate-culture.png'),
  'employee-commitment' : require('../images/explorers/employee-commitment.png'),
  'employee-engagement' : require('../images/explorers/employee-engagement.png'),
  'factors-outside-work' : require('../images/explorers/factors-outside-work.png'),
  'job-satisfaction' : require('../images/explorers/job-satisfaction.png'),
  'management-style' : require('../images/explorers/management-style.png'),
  'personal-work-ethic' : require('../images/explorers/personal-work-ethic.png'),
  'physical-health' : require('../images/explorers/physical-health.png'),
  'physical-work-environment' : require('../images/explorers/physical-work-environment.png'),
  'psychological-health' : require('../images/explorers/psychological-health.png'),
  's10' : require('../images/explorers/s10.png'),
  'soft-work-environment' : require('../images/explorers/soft-work-environment.png')
};

const formatTag = tag => tag.split(' ').join('-').toLowerCase();

export default function ExplorerTag({tag}) {
  if (!tag) return null;
  const src = images[formatTag(tag)];
  return (
    <img
      src={src}
      alt={tag}
    />
  );
}
